import { useContext, useEffect, useRef } from 'react'
import { useAmp } from 'next/amp'
import { useBeforeOnLoad } from '../../../hook/useBeforeOnLoad'
import { useUser } from '../../../hook/useUser'
import { useTMSHitForMenuBurger } from '../../../hook/useTMSClickHit'
import { useModalAccessibility } from '../../../hook/useModalAccessibility'
import { MenuContext } from '../../../contexts/MenuContext'
import { SVGIcon } from '../SVGIcon'
import { MENU_MAIN_ID, MENU_ID } from '../../../constants/components'
import { theme } from '../../../styles'

export interface IMenu {
  children: JSX.Element
}

export function Menu({ children }: IMenu): JSX.Element {
  const isAmp = useAmp()
  const menuRef = useRef()
  const { isOpen, setIsOpenMenu } = useContext(MenuContext)
  const { isLoggedIn } = useUser()
  const { menuHit } = useTMSHitForMenuBurger()

  const CloseButtonComponent = isAmp ? 'label' : 'button'
  const closeButtonProps = isAmp ? { htmlFor: MENU_ID } : { onClick: () => setIsOpenMenu(false) }
  function handleRouteChange() {
    // Close menu when route change
    setIsOpenMenu(false)
  }

  useBeforeOnLoad(handleRouteChange, isOpen)

  const { inert } = useModalAccessibility({
    show: isOpen,
    ref: menuRef.current,
    onEscape: () => setIsOpenMenu(false),
  })

  useEffect(() => {
    if (!isOpen) {
      document.documentElement.style.overflow = 'auto'
    } else {
      document.documentElement.style.overflow = 'hidden'
    }
  }, [isOpen])

  return (
    <>
      <div
        role="dialog"
        aria-modal="true"
        aria-labelledby="menu-header-title"
        className="Menu__Wrapper flex"
        ref={menuRef}
        // Prevent focus if not open
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inert={inert}
      >
        <input
          className="Menu_Input"
          id={MENU_ID}
          type="checkbox"
          autoComplete="off"
          checked={isOpen}
          tabIndex={-1}
          onChange={() => {
            menuHit(!isOpen ? 'ouverture' : 'fermeture')
            setIsOpenMenu()
          }}
        />
        <label htmlFor={MENU_ID} aria-hidden="true" className="Menu__BackDrop" />
        <div className="Menu" id={MENU_MAIN_ID} data-testid="menu-modal" tabIndex={-1}>
          <div className="Menu__Nav flex justify-end items-center">
            <CloseButtonComponent
              title="Fermer le menu"
              className="Menu__Close"
              {...closeButtonProps}
            >
              <SVGIcon
                name="close"
                size={32}
                primaryColor={!isLoggedIn ? theme.cssVars.deepBlue : theme.cssVars.white}
              />
            </CloseButtonComponent>
          </div>
          {children}
        </div>
      </div>

      <style jsx global>{`
        html {
          overflow: ${isOpen ? 'hidden' : 'auto'};
        }
      `}</style>

      <style jsx>{`
        .Menu__Nav {
          background-color: ${!isLoggedIn ? 'var(--color-white)' : 'var(--color-darkestBlue)'};
        }
      `}</style>

      <style jsx>{`
        .Menu__BackDrop {
          display: block;
          opacity: 0;
        }

        #${MENU_ID}:checked ~ .Menu__BackDrop {
          display: block;
          background-color: var(--color-backDropColor);
          z-index: 8999;
          width: 100vw;
          height: 100%;
          opacity: 1;
          transition: all 0.5s ease-in;
        }

        .Menu__Wrapper {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
        }

        .Menu {
          z-index: 9000;
          max-width: 395px;
          background: var(--color-white);
          position: fixed;
          top: 0;
          right: 0;
          width: 100vw;
          height: 100%;
          overflow-y: scroll;
          transform: translateX(100%);
          transition: transform 0.5s ease-in-out;
        }

        .Menu_Input {
          display: none;
          visibility: hidden;
        }

        #${MENU_ID}:checked ~ .Menu {
          transform: translateX(0%);
        }

        .Menu__Nav {
          padding: 30px;
        }

        .Menu__Close {
          cursor: pointer;
          border: none;
          padding: 0;
          background: transparent;
        }
      `}</style>
    </>
  )
}
