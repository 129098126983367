import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import slugify from 'slugify'
import { FacilitiButton } from '../FacilitiButton'
import { TF1InfoLogo } from '../Icon/TF1InfoLogo'
import { Link } from '../Link'
import { isPathHomeForYou } from '../../../helpers/client'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { theme, mixins } from '../../../styles'
import { MENU_SECONDARY_ID } from '../../../constants/components'
import { useViewport } from '../../../hook/useViewport'
import { slugifyForDom } from '../../../helpers/slugifyForDom'

export interface IFooterNav {
  elementList: Array<{
    title: string
    link: string
    color?: string
    target?: string
    encoded?: boolean
  }>
  wordings?: {
    choices: string
    mention: string
    accessibilityAlt: string
    accessibilityTitle: string
  }
}

export function FooterNav({ wordings, elementList }: IFooterNav): JSX.Element {
  const { asPath } = useRouter()
  const { isTagCommanderReady, hit } = useContext(TagCommanderContext)
  const openPrivacyCenter = (): void => window?.tC?.privacyCenter?.showPrivacyCenter()
  const homeForYouClass = isPathHomeForYou(asPath) ? ' FooterNav__ForYou' : ''
  const [footerMargin, setFooterMargin] = useState(0)
  const { viewport } = useViewport()

  const handleOnClick = function (title: string) {
    if (isTagCommanderReady) {
      hit(
        {
          screen_clickableElementName: `footer_${slugify(title, { lower: true })}`,
        },
        { isClickEvent: true },
      )
    }
  }

  useEffect(() => {
    const headerMenu = document.querySelector(`#${MENU_SECONDARY_ID}`)
    const headerMenuPosition = headerMenu?.getAttribute('data-position')

    if (headerMenuPosition === 'footer') {
      setFooterMargin(headerMenu.clientHeight)
    } else {
      setFooterMargin(0)
    }
  }, [viewport])

  return (
    <>
      <section className={`FooterNav__Container${homeForYouClass}`}>
        <div className="FooterNav">
          <div className="FooterNav__Logo">
            <TF1InfoLogo size={200} data-testid="footer-logo" />
          </div>
          <div>
            <ul className="FooterNav__Link flex justify-center" data-testid="footer-bloc-nav">
              {elementList.map(({ title, link, target, encoded }, index) => (
                <li key={index} aria-current={asPath === link ? 'page' : false}>
                  <Link
                    url={encoded ? '#' : link}
                    target={encoded ? undefined : target}
                    onClick={() => {
                      handleOnClick(title)
                      if (encoded) {
                        target === '_blank'
                          ? window.open(link, '_blank')
                          : window.location.assign(link)
                      }
                    }}
                    data-testid={`footer-link-${slugifyForDom(title)}`}
                  >
                    {title}
                  </Link>
                </li>
              ))}
              <li aria-current="false">
                <button
                  type="button"
                  onClick={() => {
                    handleOnClick(wordings?.choices || '')
                    openPrivacyCenter()
                  }}
                  data-testid="footer-btn-privacy-center"
                >
                  {wordings?.choices || ''}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="FooterNav__Accessibility flex justify-center">
          <FacilitiButton
            data-testid="footer-btn-accessibility"
            alt={wordings?.accessibilityAlt}
            title={wordings?.accessibilityTitle}
          />
        </div>
        <div className="FooterNav__Mention" data-testid="footer-copyright">
          <span>{wordings?.mention + new Date().getFullYear()}</span>
        </div>
      </section>

      <style jsx>{`
        .FooterNav__Container {
          background-color: ${theme.cssVars.deepBlue};
          text-align: center;
          margin-bottom: ${footerMargin}px;
        }

        .FooterNav__ForYou {
          background-color: ${theme.cssVars.darkestBlue};
        }

        .FooterNav {
          position: relative;
          width: 100%;
          display: grid;
          grid-template-rows: max-content auto;
          align-items: center;
        }

        .FooterNav button {
          cursor: pointer;
          border: none;
          background-color: transparent;
        }

        .FooterNav__Link {
          flex-wrap: wrap;
          text-align: center;
          margin: 0 30px;
          padding: 0 0 16px;
          list-style-type: none;
        }

        .FooterNav__Link li {
          padding: 5px 10px;
          font-family: ${mixins.fonts.base.small.fontFamily};
          font-style: ${mixins.fonts.base.small.fontStyle};
          font-weight: ${mixins.fonts.base.small.fontWeight};
          font-size: ${mixins.fonts.base.small.fontSize};
          line-height: ${mixins.fonts.base.small.lineHeight};
        }

        .FooterNav__Link li :global(a),
        .FooterNav__Link li button {
          color: ${theme.cssVars.midBlue};
          font-family: ${theme.fonts.overpass};
          font-size: 13px;
        }

        .FooterNav__Link li[aria-current='true'] :global(a) {
          color: ${theme.cssVars.white};
        }

        .FooterNav__Logo {
          padding: 33px 0 32px 5px;
        }

        .FooterNav__Mention {
          padding-bottom: 27px;
          color: ${theme.cssVars.white};
          font-weight: 100;
        }

        .FooterNav__Accessibility {
          padding-bottom: 53px;
        }

        .FooterNav__Accessibility > :global(button) {
          color: ${theme.cssVars.white};
          font-weight: 800;
          font-size: 11px;
        }

        @media (max-width: ${theme.breakpoints.sm}px) {
          .FooterNav__Link {
            justify-content: flex-start;
            text-align: start;
          }
        }

        @media ${mixins.mediaQuery.tablet} {
          .FooterNav__Logo {
            padding: 43px 0 36px 5px;
          }
        }
      `}</style>
    </>
  )
}
