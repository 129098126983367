import { useEffect, useRef } from 'react'
import { useAmp } from 'next/amp'
import { isServer } from '../helpers/client'

interface IUseModalAccessibility {
  show: boolean
  ref: HTMLElement
  onEscape: () => void
}

export function useRefocusOnModalClose(isOpen: boolean, elementToFocus: HTMLElement) {
  const hasOpenedOnce = useRef(false)

  useEffect(
    function handleFocusOnMenuClose() {
      if (!isOpen && hasOpenedOnce.current) {
        elementToFocus?.focus()
      }
      if (isOpen) {
        hasOpenedOnce.current = true
      }
    },
    [isOpen],
  )
}

export function useModalAccessibility({ show, ref, onEscape }: IUseModalAccessibility): {
  inert?: string
} {
  const isAmp = useAmp()
  function removeInertAttribute() {
    const allInertElements = document?.querySelectorAll('[inert]:not([role="dialog"])')
    // remove inert attribute from all elements not having role dialog
    allInertElements?.forEach((element) => {
      element.removeAttribute('inert')
    })
  }

  // Handle keydown event for escape
  function handleKeydown(e: KeyboardEvent) {
    const firstFocusableElement: HTMLElement =
      // Add data-first-focus attribute to the first focusable element of the modal
      ref?.querySelector('[data-first-focus]') ||
      ref?.querySelector(
        ':not([tabindex="-1"]):where(button, [role="button"], [href], input, select, textarea, [tabindex])',
      )
    const children = Array.from(
      ref?.querySelectorAll(
        '[data-first-focus], button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)',
      ),
    )

    const lastFocusableElement = children[children.length - 1]
    const isEscapePressed = e.key === 'Escape' || e.keyCode === 27
    const isTabPressed = e.key === 'Tab' || e.keyCode === 9

    if (isEscapePressed) {
      onEscape()
      return
    } else {
      if (
        (document.activeElement === lastFocusableElement && isTabPressed) ||
        !children.includes(document.activeElement)
      ) {
        // if focused has reached to last focusable element then focus first focusable element after pressing tab
        firstFocusableElement.focus({ preventScroll: false }) // add focus for the first focusable element
        e.preventDefault()
      }
    }
  }

  useEffect(
    function toggleBodyElementFocus() {
      if (isServer()) {
        return
      }
      if (show && ref) {
        const firstFocusableElement: HTMLElement =
          // Add data-first-focus attribute to the first focusable element of the modal
          ref?.querySelector('[data-first-focus]') ||
          ref?.querySelector(
            ':not([tabindex="-1"]):where(button, [role="button"], [href], input, select, textarea, [tabindex])',
          )
        if (firstFocusableElement) {
          firstFocusableElement?.focus?.({ preventScroll: false })
        }
        // add inert attribute to all other root elements (the modal itself should be present at root level)
        const allRootElements = document.querySelector('#__next').childNodes
        allRootElements?.forEach((node) => {
          const nodeElement = node as HTMLElement
          if (nodeElement === ref) return
          nodeElement.setAttribute('inert', 'true')
        })
        document.addEventListener('keydown', handleKeydown)
      }
      return () => {
        removeInertAttribute()
        document.removeEventListener('keydown', handleKeydown)
      }
    },
    [ref, show],
  )

  return { inert: !show && !isAmp ? 'true' : undefined }
}
