import * as React from 'react'
import { mixins, theme } from '../../../styles'

export interface IContainer {
  children: JSX.Element
  isFullRight?: boolean
  isFullWidthDesktop?: boolean
  isFullWidthTabletLandscape?: boolean
  isFollow?: boolean
}

export function Container({
  isFullRight,
  isFullWidthDesktop,
  isFullWidthTabletLandscape,
  isFollow,
  ...restProps
}: IContainer): JSX.Element {
  const Component =
    !isFullWidthTabletLandscape && !isFullWidthDesktop && !isFullRight ? 'span' : 'div'

  const classNames = [
    !isFullWidthTabletLandscape && !isFullWidthDesktop && !isFullRight && 'Container',
    isFullWidthTabletLandscape && 'Container-fluid-tl',
    isFullWidthDesktop && 'Container-fluid-d',
    isFullRight && 'Container-fluid-right',
  ].filter(Boolean)

  return (
    <>
      <Component
        className={`${classNames.join(' ')}${
          isFullWidthDesktop || isFullWidthTabletLandscape ? ' FullWidth' : ''
        }${isFollow ? ' isFollow' : ''}`}
        {...restProps}
      />

      <style jsx>{`
        :global(.Main__Body) .Container-fluid-tl,
        :global(.Main__Body) .Container-fluid-d {
          z-index: 1;
          position: relative;
        }

        .Container-fluid-tl {
          width: 100vw;
        }

        @media (min-width: ${theme.layout.desktop.body.width}px) {
          :global(.Main__Body) .Container-fluid-tl {
            z-index: 1;
            margin-left: calc(calc(-100vw + ${theme.layout.desktop.body.width}px) / 2);
          }
        }

        @media ${mixins.mediaQuery.onlyTabletPaysage} {
          :global(.Main__Body) .Container-fluid-tl {
            margin-left: calc(-100vw / 3);
          }
        }

        @media ${mixins.mediaQuery.desktop} {
          :global(.Main__Body) {
            margin: 0 auto;
          }

          .Container-fluid-d {
            width: 100vw;
            max-width: 100vw;
            min-width: 100vw;
          }

          :global(.Main__Body) .Container-fluid-d {
            margin: 0
              calc(
                calc(100vw - ${theme.layout.desktop.body.width + theme.block.marginLR * 2}px) / -2
              );
          }

          :global(.Main__Body) .Container-fluid-right {
            margin-right: calc(
              calc(100vw - (${theme.layout.desktop.body.width + theme.block.marginLR * 2}px)) / -2
            );
          }
        }
      `}</style>
    </>
  )
}
