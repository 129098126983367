import React, { Children, useCallback, useEffect, useRef, useState } from 'react'
import { PaginationProgress } from './PaginationProgress'
import { mixins } from '../../../styles'
import { isServer } from '../../../helpers/client'

export interface ICarousel {
  children: React.ReactNode
  onClick?: () => void
  isVertical?: boolean
  pagination?: 'mobile' | 'all' | false
  onIndexChange?: (index: number) => void
  withWhiteBackground?: boolean
  auto?: boolean
  withoutArrows?: boolean
}

export const INTERVAL_TIME = 5000

export function Carousel({
  children,
  onClick,
  isVertical,
  pagination,
  onIndexChange,
  withWhiteBackground,
  auto = true,
}: ICarousel): JSX.Element {
  const slideCount: number = Children.count(children)
  const swiperElRef = useRef<HTMLDivElement>(null)
  const activeElements = useRef<{ [key: number]: boolean }>({})
  const [currentIndex, setCurrentIndex] = useState(0)
  const animationIntervalRef = useRef<ReturnType<typeof setInterval>>(null)
  const isGrabbing = useRef(false)
  const startScrollX = useRef(0)
  const startScrollLeft = useRef(0)
  const hasMoved = useRef(false)

  // Get the max index to navigate (ex: 0 if all slides are visible)
  function getSlideMaxIndex() {
    if (!swiperElRef.current?.children?.[0]) return 0
    const slideElement = swiperElRef.current.children[0] as HTMLElement
    const slideWidth = slideElement.offsetWidth
    const viewportWidth = swiperElRef.current.offsetWidth
    // Calculate the maximum number of slides visible in the viewport
    const maxSlideInViewport = Math.floor(viewportWidth / slideWidth)
    // Calculate the maximum index to navigate
    const max = slideCount - maxSlideInViewport
    return max < 0 ? 0 : max
  }

  const animationScroll = useCallback(() => {
    if (animationIntervalRef.current) {
      return
    }
    animationIntervalRef.current = setInterval(() => {
      const firstVisibleElement = Object.values(activeElements.current).findIndex(Boolean)

      // If the carousel is visible and the user is not grabbing it
      if (swiperElRef.current && !isGrabbing.current && firstVisibleElement !== -1) {
        const isLastSlideVisible = activeElements.current[slideCount - 1]
        const isNextSlideExist = firstVisibleElement + 1 < slideCount
        const nextSlideIndex = firstVisibleElement < slideCount - 1 ? firstVisibleElement + 1 : 0

        // If the last slide is visible or the next slide does not exist
        // go to the first slide
        // Otherwise, go to the next slide
        goToSlideIndex(isLastSlideVisible || !isNextSlideExist ? 0 : nextSlideIndex)
      }
    }, INTERVAL_TIME)
  }, [])

  function clearAnimation() {
    if (animationIntervalRef.current) {
      clearInterval(animationIntervalRef.current)
      animationIntervalRef.current = null
    }
  }

  function resetAnimation() {
    clearAnimation()
    animationScroll()
  }

  function createIntersectionObserverParent() {
    if (isServer()) {
      return null
    }
    return new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (swiperElRef.current) {
            // If the carousel is more than 50% visible, start the animation
            if (entry.intersectionRatio >= 0.5) {
              animationScroll()
            } else {
              clearAnimation()
            }
          }
        })
      },
      {
        threshold: [0, 1],
      },
    )
  }

  function createIntersectionObserver() {
    if (isServer()) {
      return null
    }

    return new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (swiperElRef.current) {
            const { target } = entry
            const children = Array.from(swiperElRef.current.children)
            const index = children.indexOf(target)

            // If the slide is more than 50% visible, mark it as active
            if (entry.intersectionRatio >= (isGrabbing.current ? 0.5 : 0.8)) {
              target.classList.add('swiper-slide-active')
              // Mark the slide as active (visible in the viewport)
              activeElements.current[index] = true
            } else {
              target.classList.remove('swiper-slide-active')
              activeElements.current[index] = false
            }
          }
        })
      },
      {
        root: swiperElRef.current,
        rootMargin: '100% 0px 100% 0px', // only on x axe
        threshold: [0, 0.25, 0.5, 0.75, 1],
      },
    )
  }

  function handleMouseDown(e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()

    // Prevent the click event to be triggered if the user is dragging the carousel
    swiperElRef.current.addEventListener('click', disableEvent)

    // Update the state to indicate that the user is grabbing the carousel
    isGrabbing.current = true
    swiperElRef.current.classList?.add('swiper-wrapper__grap')
    swiperElRef.current.classList?.add('swiper-wrapper__grap_smooth')

    // Stop animation during the drag
    clearAnimation()

    // Save the initial position of the mouse and the scroll
    startScrollX.current = e.pageX - swiperElRef.current.offsetLeft
    startScrollLeft.current = swiperElRef.current.scrollLeft
  }

  function handleMouseMove(e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
    // Only move the carousel if the user is grabbing it
    if (isGrabbing.current) {
      // Calculate the distance between the initial position and the current position
      const x = e.pageX - swiperElRef.current.offsetLeft
      const walk = x - startScrollX.current
      // Update the scroll position
      swiperElRef.current.scrollLeft = startScrollLeft.current - walk
      // Determine if the user has moved the carousel
      hasMoved.current = Math.abs(walk) > 5
    }
  }

  function handleMouseUp(e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()

    // If the user has not moved the carousel enough
    // We consider that it is a click event
    // So we remove the event listener to prevent the click event to be triggered
    if (!hasMoved.current) {
      swiperElRef.current.removeEventListener('click', disableEvent)
      return
    }
    hasMoved.current = false
    // Calculate the max index to navigate
    const maxIndex = getSlideMaxIndex()
    // Select the closest visible element to the current scroll position
    const firstClosestElement = Object.keys(activeElements.current).find(
      (key) => activeElements.current[key],
    )

    // No more grabbing
    isGrabbing.current = false

    // Reactivate the smooth scroll to smooth scroll to the closest element
    swiperElRef.current.classList?.remove('swiper-wrapper__grap_smooth')

    // Navigate to the closest element if it exists or the end of the carousel
    goToSlideIndex(
      firstClosestElement ? Math.min(parseInt(firstClosestElement, 10), maxIndex) : maxIndex,
    )

    // Prevent a flash scroll when the user releases the carousel
    setTimeout(() => {
      swiperElRef.current.classList?.remove('swiper-wrapper__grap')
    }, 300)
  }

  function handleScrollEnd() {
    if (isGrabbing.current) {
      return
    }
    const newIndex = Object.values(activeElements.current).findIndex(Boolean)
    if (newIndex !== -1) {
      setCurrentIndex(Object.values(activeElements.current).findIndex(Boolean))
    }
  }

  function goToSlideIndex(index: number) {
    // Check if the index is in the range of the slides
    // and slide html element exists
    if (index < slideCount && swiperElRef.current?.children?.[index]) {
      const slideElement = swiperElRef.current.children[index] as HTMLElement
      swiperElRef.current.scrollLeft = slideElement.offsetLeft
      setCurrentIndex(index)
    }
  }

  function disableEvent(e: Event) {
    e.preventDefault()
    e.stopPropagation()
  }

  //Add the event listeners and observer
  useEffect(() => {
    if (swiperElRef.current && auto) {
      // Initialize the observer for the slides to detect if they are visible
      // and mark them as active

      // Initialize the observer for the carousel to detect if it is visible
      // and start the animation
      const observerParent = createIntersectionObserverParent()
      const slidesIntersectionObserver = createIntersectionObserver()

      const children = Array.from(swiperElRef.current.children)
      observerParent?.observe(swiperElRef.current)

      children.map((section) => {
        slidesIntersectionObserver?.observe?.(section)
        // Prevent drag and select on slide elements (image, title, etc.)
        // Only for desktop user sliding with mouse
        section.addEventListener('dragstart', disableEvent)
        section.addEventListener('selectstart', disableEvent)
      })

      // Add the event listener to detect the end of the scroll
      swiperElRef.current.addEventListener('scrollend', handleScrollEnd, { passive: true })

      // Add the event listeners to handle the drag
      // Only for desktop user sliding with mouse
      swiperElRef.current.addEventListener('mousedown', handleMouseDown)
      swiperElRef.current.addEventListener('mousemove', handleMouseMove)
      swiperElRef.current.addEventListener('mouseup', handleMouseUp)
      swiperElRef.current.addEventListener('mouseleave', handleMouseUp)

      return () => {
        observerParent?.disconnect()
        slidesIntersectionObserver?.disconnect?.()

        children?.map((section) => {
          section?.removeEventListener?.('dragstart', disableEvent)
          section?.removeEventListener?.('selectstart', disableEvent)
        })

        swiperElRef.current?.removeEventListener('scrollend', handleScrollEnd)

        swiperElRef.current?.removeEventListener('mousedown', handleMouseDown)
        swiperElRef.current?.removeEventListener('mousemove', handleMouseMove)
        swiperElRef.current?.removeEventListener('mouseup', handleMouseUp)
        swiperElRef.current?.removeEventListener('mouseleave', handleMouseUp)

        if (animationIntervalRef.current) {
          clearAnimation()
        }
      }
    }
  }, [])

  useEffect(() => {
    if (onIndexChange) {
      onIndexChange(currentIndex)
    }
    // On index change, reset the animation
    // to prevent the carousel to move to the next slide during a manual navigation
    if (Object.values(activeElements.current).some(Boolean)) {
      resetAnimation()
    }
  }, [currentIndex])

  if (slideCount === 0) return null

  if (slideCount === 1) {
    return <div className="Carousel">{children}</div>
  }

  return (
    <>
      <div className="Carousel">
        <PaginationProgress
          numberOfItems={slideCount}
          className="Carousel__Pagination-progress"
          withWhiteBackground={withWhiteBackground}
          currentIndex={currentIndex}
          onClick={goToSlideIndex}
        />
        <div ref={swiperElRef} className="swiper-wrapper" onClick={onClick}>
          {children}
        </div>
      </div>
      <style jsx>{`
        .Carousel :global(.PaginationProgress) {
          display: ${(pagination && pagination === 'all') || pagination === 'mobile'
            ? 'flex'
            : 'none'};
        }

        @media ${mixins.mediaQuery.tablet} {
          .Carousel :global(.PaginationProgress) {
            display: ${(pagination && pagination === 'all') ||
            (pagination !== false && pagination !== 'mobile')
              ? 'flex'
              : 'none'};
          }
        }
      `}</style>
      <style jsx>{`
        .Carousel {
          display: ${isVertical ? 'flex' : 'block'};
        }

        .Carousel :global(.Carousel__Pagination-progress) {
          position: ${isVertical ? 'relative' : 'absolute'};
          padding: ${isVertical ? '30px 30px 0' : '0 30px'};
          top: ${isVertical ? 'initial' : '32px'};
        }

        .swiper-wrapper {
          grid-auto-columns: ${isVertical ? 'min(45%, 375px)' : '100%'};
          grid-gap: ${isVertical ? '10px' : '0'};
          max-height: ${isVertical ? '560px' : '100%'};
        }

        @media ${mixins.mediaQuery.tablet} {
          .swiper-wrapper {
            grid-auto-columns: ${isVertical ? 'minmax(min-content, 375px)' : 'max-content'};
            grid-gap: ${isVertical ? '20px' : '50px'};
          }
        }
      `}</style>
      <style jsx>{`
        .Carousel {
          flex-direction: column-reverse;
          position: relative;
          max-width: 100%;
          overscroll-behavior-x: none;
        }

        .Carousel :global(.Carousel__Pagination-progress) {
          width: 100%;
          z-index: 9;
        }

        .swiper-wrapper {
          display: grid;
          position: relative;
          margin: 0;
          padding: 0;
          overflow-x: scroll;
          scroll-behavior: smooth;
          scroll-snap-type: x mandatory;
          -webkit-overflow-scrolling: touch;
          grid-auto-flow: column;
          list-style: none;
          /*FireFox*/
          scrollbar-width: none;
          overscroll-behavior-x: none;
          max-width: 100vw;
          transition-property: transform;
        }

        .swiper-wrapper::-webkit-scrollbar {
          /*Chrome, Safari, Edge*/
          display: none;
        }

        .swiper-wrapper.swiper-wrapper__grap {
          scroll-snap-type: none;
        }

        .swiper-wrapper.swiper-wrapper__grap_smooth {
          scroll-behavior: auto;
        }

        .swiper-wrapper:active,
        .swiper-wrapper:active * {
          cursor: grab;
        }

        .swiper-wrapper__grap,
        .swiper-wrapper__grap > :global(* a:before) {
          cursor: grab;
        }

        .swiper-wrapper::after {
          content: '';
          display: inline-block;
          flex: 0 0 auto;
          width: 1px;
          margin-left: -1px;
        }

        .swiper-wrapper ::-webkit-scrollbar {
          display: none;
        }

        .swiper-wrapper > :global(*) {
          scroll-snap-align: start;
          scroll-snap-stop: always;
        }

        .swiper-wrapper.swiper-wrapper__grap > :global(*) {
          scroll-snap-align: none;
          scroll-snap-stop: unset;
        }

        @media ${mixins.mediaQuery.tablet} {
          .Carousel {
            display: flex;
            flex-direction: column-reverse;
          }

          .Carousel :global(.Carousel__Pagination-progress) {
            position: relative;
            top: initial;
            padding: 30px 30px 0;
          }

          .swiper-wrapper {
            grid-gap: 50px;
            padding-right: 375px;
          }

          .Carousel__Button-hidden {
            display: none;
          }
        }
      `}</style>
    </>
  )
}
