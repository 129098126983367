import type { NextRouter } from 'next/router'

import { useContext, useState } from 'react'
import { useAmp } from 'next/amp'
import { useRouter } from 'next/router'
import slugify from 'slugify'
import { Button } from '../../atoms/Button'
import { User } from '../../atoms/Icon/Navigation/User'
import { Play } from '../../atoms/Icon/Content/Play'
import { LciLogo } from '../../atoms/Icon/LciLogo'
import { TF1Logo } from '../../atoms/Icon/TF1Logo'
import { ButtonBanner } from '../../atoms/ButtonBanner'
import { getFirstName } from '../../../helpers/gigya'
import { Input } from '../../atoms/Input'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { useTMSHitForMenuBurger } from '../../../hook/useTMSClickHit'
import { useUser } from '../../../hook/useUser'
import { setupOriginUrlCookie } from '../../../helpers/cookies'
import { SEARCH_ID } from '../../../constants/components'
import { theme } from '../../../styles'
export interface IMenuHeader {
  title: string
  hello?: string
  register: string
  login: string
  logout: string
  myAccount: string
  direct: string
  directTF1: string
  jtPerso: string
  myPreferences: string
  searchPlaceholder?: string
}

export function MenuHeader({
  title,
  hello,
  register,
  login,
  logout,
  myAccount,
  jtPerso,
  myPreferences,
  searchPlaceholder,
  direct,
  directTF1,
}: IMenuHeader): JSX.Element {
  const isAmp = useAmp()
  const [searchInputValue, setSearchInputValue] = useState<string>('')
  const { isTagCommanderReady, hit } = useContext(TagCommanderContext)
  const { asPath }: NextRouter = useRouter()
  const { isLoggedIn, disconnectUser, userProfile } = useUser()
  const firstname = getFirstName(userProfile)
  const { menuHit } = useTMSHitForMenuBurger()

  function onClickSearch() {
    const query = searchInputValue?.trim() || ''
    if (!query) return
    if (isTagCommanderReady) {
      hit(
        {
          screen_clickableElementName: `search-tf1info-${slugify(query, { lower: true })}`,
        },
        { isClickEvent: true },
      )
    }
  }

  const handleButtonAccountClick = () => {
    menuHit(isLoggedIn ? myAccount : register)
    setupOriginUrlCookie(asPath)
    window.location.href = isLoggedIn ? '/compte/' : `/compte/inscription/?serviceType=Menu`
  }

  const handleJTPersoClick = () => {
    menuHit(jtPerso)
    setupOriginUrlCookie(asPath)
  }

  function onClickLoggedIn() {
    setupOriginUrlCookie(asPath)
    window.location.href = '/compte/connexion/'
  }

  function handleSearchInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    setSearchInputValue(e.target.value)
  }

  function handleLoginLogoutClick() {
    menuHit(isLoggedIn ? logout : login)
    isLoggedIn ? disconnectUser() : onClickLoggedIn()
  }

  const menuHeaderTitle =
    isLoggedIn && firstname
      ? `${hello} <strong>${firstname}</strong>`
      : // remove <p> tag from title as it is already in the parent component
        title?.replace(/<\/?p>/gm, '') || ''

  return (
    <>
      <div data-testid="MenuHeader" className="MenuHeader">
        <p
          role="heading"
          aria-level={1}
          id="menu-header-title"
          data-testid="menu-txt-bienvenue"
          className="MenuHeader__Title flex flex-column justify-center"
          dangerouslySetInnerHTML={{ __html: menuHeaderTitle }}
        />
        <form
          className="MenuHeader__SearchBar"
          action="/recherche/"
          role="search"
          id={SEARCH_ID}
          onSubmit={onClickSearch}
          tabIndex={-1}
          target="_top"
        >
          <Input
            id="searchInput"
            data-testid="menu-input-search"
            title={searchPlaceholder}
            placeholder={searchPlaceholder}
            onChange={handleSearchInputChange}
            type="search"
            name="query"
            value={searchInputValue}
          >
            <button
              className="MenuHeader__Input__Icon"
              title="Lancer votre recherche de programme"
              data-testid="menu-btn-search"
              type="submit"
            >
              <img src="/images/icons/search.svg" alt="" />
            </button>
          </Input>
        </form>
        <div className="MenuHeader__Buttons">
          <Button
            textColor={!isLoggedIn ? theme.cssVars.white : theme.cssVars.darkestBlue}
            backgroundColor={!isLoggedIn ? theme.cssVars.deepBlue : theme.cssVars.lightBlue}
            testId={isLoggedIn ? 'menu-btn-account-home' : 'menu-btn-account-registration'}
            href={isAmp ? '/compte/inscription/?serviceType=Menu' : undefined}
            onClick={handleButtonAccountClick}
          >
            <>
              <User
                color={!isLoggedIn ? theme.cssVars.white : theme.cssVars.darkestBlue}
                size={20}
              />
              {isLoggedIn ? myAccount : register}
            </>
          </Button>
          <Button
            href={isAmp ? '/compte/connexion/' : null}
            textColor={!isLoggedIn ? theme.cssVars.deepBlue : theme.cssVars.lightBlue}
            backgroundColor="transparent"
            onClick={handleLoginLogoutClick}
            testId={isLoggedIn ? 'btn-menu-account-logout' : 'btn-menu-account-login'}
          >
            {isLoggedIn ? logout : login}
          </Button>
        </div>
      </div>

      <ul className="MenuHeader__Banners" data-testid="menu-header-banners">
        <li>
          <ButtonBanner
            href="/direct/"
            textColor={theme.cssVars.white}
            backgroundColor={theme.cssVars.red}
            testId="menu-btn-direct"
            onClick={() => menuHit(direct)}
            title={`${direct} LCI`}
          >
            <Play id="playlogoDirect" size={45} /> {direct} <LciLogo id="lcilogo" size={38} />
          </ButtonBanner>
        </li>
        <li>
          <ButtonBanner
            href="/direct/tf1/"
            textColor={theme.cssVars.white}
            backgroundColor={theme.cssVars.deepBlue}
            testId="menu-btn-direct-tf1"
            onClick={() => menuHit(directTF1)}
            title={`${directTF1} TF1`}
          >
            <Play id="playlogoTF1" size={45} /> {directTF1} <TF1Logo id="tf1logo" />
          </ButtonBanner>
        </li>
        <li>
          <ButtonBanner
            href="/jt-perso/presentation/"
            textColor={theme.cssVars.white}
            backgroundColor={theme.cssVars.yellowGolden}
            testId="menu-btn-jtperso"
            onClick={handleJTPersoClick}
          >
            <Play id="playlogoJT" size={45} /> {jtPerso}
          </ButtonBanner>
        </li>

        {isLoggedIn ? (
          <li>
            <ButtonBanner
              href="/compte/mes-sujets/"
              textColor={theme.cssVars.deepBlue}
              backgroundColor="transparent"
              id="MenuButtonPreferences"
              onClick={() => menuHit(myPreferences)}
              testId="menu-btn-thematiques"
            >
              <img src="/images/icons/params.svg" alt="preferences" /> {myPreferences}
            </ButtonBanner>
          </li>
        ) : null}
      </ul>

      <style jsx>{`
        .MenuHeader {
          background-color: ${!isLoggedIn ? theme.cssVars.white : theme.cssVars.darkestBlue};
        }

        .MenuHeader__Title {
          color: ${!isLoggedIn ? theme.cssVars.deepBlue : theme.cssVars.white};
        }
      `}</style>

      <style jsx>{`
        .MenuHeader {
          padding: 30px;
        }

        .MenuHeader__Title {
          margin: 0 0 30px;

          text-align: center;
          font-size: 38px;
          font-weight: 100;
        }

        .MenuHeader__Input__Icon {
          cursor: pointer;
          background-color: transparent;
          border: transparent;
        }

        .MenuHeader__Input__Icon img {
          width: 35px;
        }

        .MenuHeader__Title :global(strong) {
          display: block;
          font-weight: 800;
        }

        .MenuHeader__Buttons :global(svg) {
          margin-right: 12px;
        }

        .MenuHeader__Buttons :global(a),
        .MenuHeader__Buttons :global(button) {
          font-family: ${theme.fonts.overpass};
          margin-right: 12px;
        }

        .MenuHeader__Banners :global(#MenuButtonPreferences) {
          display: grid;
          grid-template-columns: auto auto;
        }

        .MenuHeader__Banners :global(a) {
          display: grid;
          grid-template-columns: auto 56% auto;
        }

        .MenuHeader__Banners {
          margin-bottom: 30px;
          list-style: none;
          padding: 0;
        }

        .MenuHeader__Banners > :global(*) {
          margin-bottom: 2px;
        }

        .MenuHeader__Banners > :global(*:last-child) {
          margin-bottom: 0;
        }

        .MenuHeader__Banners :global(#playlogoDirect),
        .MenuHeader__Banners :global(#playlogoTF1),
        .MenuHeader__Banners :global(#playlogoJT) {
          margin-right: 20px;
        }

        .MenuHeader__Banners :global(#lcilogo) {
          margin-bottom: 4px;
          margin-left: 10px;
        }

        .MenuHeader__Banners :global(#tf1logo) {
          margin-left: 10px;
          margin-bottom: 4px;
          width: 40px;
        }

        .MenuHeader__Banners :global(img) {
          margin-right: 16px;
        }

        .MenuHeader__SearchBar {
          margin-bottom: 30px;
        }

        .MenuHeader__SearchBar :global(input) {
          outline: none;
        }
      `}</style>
    </>
  )
}
