import { useState } from 'react'
import { useAmp } from 'next/amp'
import { stringToId } from '../../../helpers/stringToId'
import { useTMSHitForMenuBurger } from '../../../hook/useTMSClickHit'
import { Link } from '../Link'
import { SVGIcon } from '../SVGIcon'
import { theme } from '../../../styles'

export interface IMenuLinks {
  title: string
  elementList: Array<{
    title: string
    link: string
    encoded?: boolean
    elementList?: Array<{ title: string; link: string; encoded?: true }>
  }>
  openByDefault?: boolean
}

function MenuList({
  title,
  elementList,
  onItemClick,
}: {
  title: string
  elementList: IMenuLinks['elementList']
  onItemClick: (title: string, itemTitle: string, linkTitle?: string) => void
}): JSX.Element {
  const isAmp = useAmp()
  if (!elementList?.length) return null
  return (
    <>
      {elementList.map(({ title: itemTitle, link: itemLink, elementList: menuList, encoded }) => {
        const useEncodedLinks = encoded && !isAmp
        // Submenu with links
        return menuList?.length ? (
          <li key={stringToId(itemTitle + itemLink)} className="MenuList">
            <Link
              url={useEncodedLinks ? '#' : itemLink}
              onClick={() => {
                onItemClick(title, itemTitle)
                if (useEncodedLinks) {
                  window.location.assign(itemLink)
                }
              }}
            >
              <div className="MenuList__Title MenuList__Link flex">{itemTitle}</div>
            </Link>
            <ul className="MenuList__List_SubList">
              {menuList?.length &&
                menuList.map(({ title: linkTitle, link }) => (
                  <li
                    key={stringToId(linkTitle + link)}
                    className="MenuList__Item MenuList__Link"
                    onClick={() => {
                      onItemClick(title, itemTitle, linkTitle)
                      if (useEncodedLinks) {
                        window.location.assign(link)
                      }
                    }}
                  >
                    <Link url={useEncodedLinks ? '#' : link}>{linkTitle} </Link>
                  </li>
                ))}
            </ul>
          </li>
        ) : (
          // link
          <li
            key={stringToId(itemTitle + itemLink)}
            className="MenuList__Item MenuList__Link"
            onClick={() => {
              onItemClick(title, itemTitle)
              if (useEncodedLinks) {
                window.location.assign(itemLink)
              }
            }}
          >
            <Link url={useEncodedLinks ? '#' : itemLink}>{itemTitle}</Link>
          </li>
        )
      })}

      <style jsx>{`
        .MenuList__List_SubList {
          list-style: none;
          margin: 0;
          padding: 0 0 0 30px;
        }

        .MenuList__Item {
          margin-bottom: 30px;
        }

        .MenuList__Item :last-child {
          margin-bottom: 0;
        }

        .MenuList__Title {
          padding: 0;
          margin: 0 0 30px 0;
        }

        .MenuList__Link {
          font-family: ${theme.fonts.overpass};
          color: ${theme.colors.deepBlue};
          font-weight: 300;
          font-size: 18px;
          line-height: 144%;
        }
      `}</style>
    </>
  )
}

export function MenuLinks({ title, elementList, openByDefault }: IMenuLinks): JSX.Element {
  const isAmp = useAmp()
  const [isActive, setIsActive] = useState(!!openByDefault)
  const dropMenuId = stringToId(`drop-menu-${title}`)
  const { menuHit } = useTMSHitForMenuBurger()

  const handleOnclick = () => {
    menuHit()
    setIsActive(!isActive)
  }
  const propsAmp = {
    on: `tap:${`amp-accordion-${dropMenuId}`}.toggle`,
  }

  return (
    <>
      <div className="MenuLinks">
        {!isAmp && (
          <button
            className={`MenuLinks__Dropdown${isActive ? ' MenuLinks__Dropdown--active' : ''} flex`}
            onClick={handleOnclick}
            aria-expanded={isActive}
            aria-controls={dropMenuId}
          >
            <div className="MenuLinks__Chevron">
              <SVGIcon name="chevron" orientation="up" />
            </div>
            <div className="MenuLinks__Title">{title}</div>
          </button>
        )}
        {isAmp ? (
          <amp-accordion id={`amp-accordion-${dropMenuId}`}>
            <section>
              <h2 className="MenuLinks__Accordion__Header">
                <button className="MenuLinks__Dropdown flex" {...propsAmp}>
                  <div className="MenuLinks__Chevron">
                    <SVGIcon name="chevron" orientation="up" />
                  </div>
                  <div className="MenuLinks__Title">{title}</div>
                </button>
              </h2>
              <ul id={`amp-menu-list-${dropMenuId}`} className="MenuLinks__List">
                <MenuList title={title} elementList={elementList} onItemClick={menuHit} />
              </ul>
            </section>
          </amp-accordion>
        ) : (
          <ul id={`menu-list-${dropMenuId}`} className="MenuLinks__List">
            <MenuList title={title} elementList={elementList} onItemClick={menuHit} />
          </ul>
        )}
      </div>

      <style jsx>{`
        #${`amp-accordion-${dropMenuId}`}:checked + .MenuLinks__Dropdown,
        .MenuLinks__Dropdown--active,
        h2[aria-expanded='true'] {
          margin-bottom: 38px;
        }

        #${`amp-accordion-${dropMenuId}`}:checked + .MenuLinks__Dropdown > .MenuLinks__Chevron :global(> svg),
        .MenuLinks__Dropdown--active > .MenuLinks__Chevron :global(> svg),
        h2[aria-expanded='true'] .MenuLinks__Chevron :global(> svg) {
          transform: rotate(90deg);
        }

        #${`amp-accordion-${dropMenuId}`}:checked + label + .MenuLinks__List,
        .MenuLinks__Dropdown--active + .MenuLinks__List {
          display: block;
          visibility: visible;
        }
      `}</style>
      <style jsx>{`
        .MenuLinks {
          width: 100%;
          padding: 0 30px 60px;
        }

        .MenuLinks__Dropdown {
          width: 100%;
          text-align: left;
          cursor: pointer;
          border: none;
          background: transparent;
          padding: 0;
        }

        .MenuLinks__Chevron :global(svg) {
          transition: transform 0.3s ease;
        }

        .MenuLinks__Dropdown :global(svg) {
          margin-right: 4px;
        }

        .MenuLinks__Title {
          font-size: 30px;
          font-weight: 700;
          line-height: 116%;
          color: ${theme.colors.deepBlue};
          margin: 0;
        }

        .MenuLinks__Accordion__Header {
          border: none;
          background-color: transparent;
        }

        .MenuLinks__List {
          list-style: none;
          margin: 0;
          padding: 0 0 0 30px;
        }

        .MenuLinks__List {
          display: ${isAmp ? 'block' : 'none'};
          visibility: ${isAmp ? 'visible' : 'hidden'};
        }
      `}</style>
    </>
  )
}
